* {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  font-family: 'Gotham A', 'Gotham B', -apple-system, BlinkMacSystemFont, sans-serif;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

:root {
  --app-height: 100%;
}

html, body {
  width: 100vw;
  min-height: 100vh; /* height: 100%; */
  max-width: 100%;
  padding: 0;
  margin: 0;
  overflow-x: hidden;
}

body {
  min-height: -webkit-fill-available;
  background-color: #000;
}

#root {
  height: 100%;
}

@media (max-width: 400px) {
  p {
    font-size: 14px;
  }
}