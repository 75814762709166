.carousel .slide {
  text-align: left;
}

.carousel .control-dots .dot {
  box-shadow: none;
  opacity: 1;
}

.carousel .control-dots .dot.selected {
  background-color: rgba(0,0,0,0.4);
}

.carousel .control-dots .dot.unselected {
  background-color: white;
  border: 1px solid rgba(0,0,0,0.4)
}

@media (max-width: 400px) {
  .carousel ul.control-dots {
    bottom: -14px;
  }
}